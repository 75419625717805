import { Application } from "@hotwired/stimulus"

import Label from "./components/label/Label"
import Modal from "./components/modal/Modal"
import Tooltip from "./components/tooltip/Tooltip"

const app = Application.start()
window.app = app.register("label", Label)
window.app = app.register("modal", Modal)
window.app = app.register("tooltip", Tooltip)
