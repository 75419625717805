import { Controller } from "@hotwired/stimulus"

const HIDDEN_CLASS = "-hidden"

export default class Tooltip extends Controller {
  static targets = ["tooltipContent"]

  displayTooltip(event) {
    const dom = this.tooltipContentTarget
    dom.classList.remove(HIDDEN_CLASS)
    if (event.clientX + dom.offsetWidth * 2 > window.innerWidth) {
      dom.style.left = `${event.clientX - dom.offsetWidth - 10}px`
    } else {
      dom.style.left = `${event.clientX + 10}px`
    }
    dom.style.top = `${window.scrollY + event.clientY + 10}px`
  }

  hideTooltip() {
    this.tooltipContentTarget.classList.add(HIDDEN_CLASS)
  }
}
