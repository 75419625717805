import { Controller } from "@hotwired/stimulus"

export default class Modal extends Controller {
  static values = { removeOnQuit: String }

  quit() {
    if (this.removeOnQuitValue === "true") {
      this.element.remove()
    } else {
      this.element.classList.add("-hidden")
    }
  }

  blur(event) {
    if (!this.element.contains(event.target) || this.element === event.target) this.quit()
  }

  handleKeyup(event) {
    if (event.key === "Escape") this.quit()
  }
}
